import React from "react"
import { NotFoundWrapper } from '../assets/styles'

const NotFoundPage = () => (
  <NotFoundWrapper>
    <h1>404</h1>
    <h1>Page Not Found</h1>
    <h1>The Page you are looking for doesn't exist or an other error occured.</h1>
  </NotFoundWrapper>
)

export default NotFoundPage